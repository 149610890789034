<template>
    <div class="client-table position-relative w-100 h-100 px-4 py-3 d-flex flex-column overflow-hidden">
	   <table-summary title="Client" filter="name" sort="name"
	                  v-bind:order="1"
	                  v-bind:fields="fields"
	                  v-bind:data-array="clients"
	                  v-on:add-new="addClient"
	                  v-on:row-clicked="navigate($event)"></table-summary>
    </div>
</template>

<script>
import TableSummary from "@/components/functional/table/TableSummary";

export default {
    name: "ClientTable",
    
    components: {
    	TableSummary,
    },
    
    data() {
        return {
            fields: [
                { display: "Client No.", key: "clientNo", classBinding: ["f-2"] },
                { display: "Name", key: "name", classBinding: ["f-4"] },
                { display: "Type", key: "type", classBinding: [] },
	            { display: "Credit Limit", key: "creditLimit", classBinding: [] },
                { display: "Contact", key: "contacts", classBinding: ["f-2"] },
                { display: "Phone", key: "phone", classBinding: [] },
                { display: "Email", key: "email", classBinding: ["f-2"] },
                { display: "Handler", key: "handler", classBinding: ["f-2"] },
                { display: "Address", key: "address", classBinding: ["f-5"] },
            ],
        }
    },
    
    computed: {
        clients() {
            return this.$store.state.contents.clients.map(client => {
                const obj = {...client};
                const handler = this.$store.state.contents.staffs.find(({ _id }) => _id === client.handler);
                obj.handler = handler ? `${handler.preferredName} ${handler.lastName}` : "N/A";
                if (client.contacts.length > 0) {
                    obj.contacts = client.contacts[0].name ? client.contacts[0].name : "N/A";
                    obj.phone = client.contacts[0].phone ? client.contacts[0].phone : "N/A";
                    obj.email = client.contacts[0].email ? client.contacts[0].email : "N/A";
                }
                return obj;
            });
        },
    },
    
    methods: {
	    async addClient() {
		    await this.$router.push("/client/add");
	    },
	    
	    async navigate(dataObj) {
        	await this.$router.push(`/client/${dataObj._id}`);
	    }
    }
}
</script>

<style lang="scss" scoped>

</style>